@import url('https://fonts.googleapis.com/css2?family=Sora&display=swap');

* {
    font-family: 'Sora', sans-serif;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.menu a {
    color: black;
}

.error {
    margin: 0 0 10px 10px;
    text-align: left;
    font-size: 15px;
    color: red;
}

.icon-style {
    font-size: 24px !important;
}

.form {
    padding: 50px 20px 20px 20px;
    border-radius: 20px;
    background-color: #F8F9FA;
}

.row {
    margin-left: -15px;
    margin-right: -15px;
}

.link {
    color: black;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
}

/*for Navigation*/
.nav-link {
    text-align: center;
}

.navbar-light .navbar-nav .nav-link.active {
    color: #154ffc !important;
}

.MuiTable-root p {
    margin-bottom: 0;
}

/*for Transaction table*/
.table-th {
    font-size: 16px;
    text-align: center;
}

.table-td {
    font-family: 'Fira Code', monospace;
    font-size: 16px;
    text-align: center;
}
.table-td b {
    font-family: 'Fira Code', monospace;
}

.table-footer {
    font-size: 16px;
}

.gridjs-th-content {
    padding: 4.5px 0;
}

.table-td {
    padding: 6px 24px !important;
    text-align: end;
}

@media only screen and (max-width: 600px) {
    .table-th {
        font-size: 10px;
        padding-right: 1px !important;
        padding-left: 1px !important;
    }

    .table-td {
        font-size: 10px;
        /*padding-left: 10px;*/
        /*padding-right: 10px;*/
    }

    .table-footer {
        font-size: 10px;
    }
}